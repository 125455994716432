// export function indexList() {
// 	const indexList = [
// 		6486,
// 		6485,
// 		39485,
// 		83525,
// 		86347,
// 		33246,
// 		33196,
// 		84815,
// 		42923,
// 		769,
// 		45797,
// 		6481,
// 		110037,
// 		42054,
// 		83599,
// 		84469,
// 		720466,
// 		33247,
// 		83385,
// 		86052,
// 		85500,
// 		86289,
// 		83651,
// 		86237,
// 		6486,
// 		6485,
// 		323658,
// 		999815,
// 		999813,
// 		323663,
// 		323670,
// 		323691,
// 		323703,
// 		323711,
// 		323722,
// 		85338,
// 		85301
// 	];
// 	return indexList;
// }
import { worldIndexes } from "@/utilities/worldIndexes.js";
import { sweIndexes } from "@/utilities/swedenIndexes.js";
export const indexList = worldIndexes.concat(sweIndexes);
export const dowJones = [
  86289, 83385, 83525, 85301, 83599, 39485, 83651, 86052, 85500, 84815, 86347,
  85338,
];

export function disableDowJonesIndices({
  selectedIndex,
  intervalButtons,
  changeChartInterval,
}) {
  if (dowJones.includes(selectedIndex)) {
    intervalButtons.forEach((e) => {
      const condition = !["0d", "1d"].includes(e.value);
      e.disabled = condition;
      if (!e.disabled && !condition) changeChartInterval(e);
    });
  } else {
    intervalButtons.forEach((e) => {
      e.disabled = false;
    });
  }
}
