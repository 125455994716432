<template>
  <div class="chart-wrapper">
    <div class="chart-header">
      <select :id="props.timeperiod" v-show="false">
        <option value="0d">Idag</option>
        <option value="1d">1 dag</option>
        <option value="2d">2 dagar</option>
        <option value="3d">3 dagar</option>
        <option value="5d">1 v</option>
        <option value="1m">1 mån</option>
        <option value="3m">3 mån</option>
        <option value="6m">6 mån</option>
        <option value="ytd">i år</option>
        <option value="1y">1 år</option>
        <option value="2y">2 år</option>
        <option value="3y">3 år</option>
        <option value="4y">4 år</option>
        <option value="5y">5 år</option>
        <option value="7y">7 år</option>
        <option value="10y">10 år</option>
      </select>
      <List
        v-if="header"
        :instrument="[instrument]"
        :fields="props.headerFields"
        :targetId="'chartList'"
        :custom_names="props.headerCustomNames"
        :controlcolumn="props.headerControlcolumn"
        :stylecolumn="props.headerStylecolumn"
        ><h1 class="millistream-name" v-if="props.customH1Name">
          {{ headerName }}
        </h1></List
      >
      <div class="compare-wrapper">
        <div
          v-for="(elem, index) in selectedCompares.filter(
            (elem) => elem != null
          )"
          :class="elem.class"
          @click="removeCompare(elem)"
        >
          {{ elem?.name }}
        </div>
      </div>
    </div>
    <div style="height: 300px">
      <div :id="props.targetId"></div>
    </div>
    <div
      v-if="props.intervalButtons.length || compareOptions.length"
      class="below-chart-menu"
    >
      <div class="chart-button-container" v-if="props.intervalButtons.length">
        <button
          v-for="elem in props.intervalButtons"
          :key="elem.value"
          :id="elem.value"
          class="round-button"
          :disabled="elem.active || elem.disabled"
          :class="elem.active ? 'active' : ''"
          @click="changeChartInterval(elem)"
        >
          {{ elem.text }}
        </button>
      </div>

      <div
        class="mobile-button-container"
        id="intervall-drop-down"
        :class="showIntervals ? ' open' : ''"
        v-if="props.intervalButtons"
        v-click-outside="() => (showIntervals = false)"
      >
        <div id="selected-div" @click="showIntervals = !showIntervals">
          <span>{{ selectedInterval.text }}</span>
          <div class="arrow">
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </div>
        <div class="mobile-button-options" v-show="showIntervals">
          <button
            class="mobile-button-option"
            v-for="elem in intervalButtons"
            :disabled="elem.active"
            :key="elem"
            @click="changeChartInterval(elem)"
          >
            {{ elem.text }}
          </button>
        </div>
      </div>

      <Dropdown
        v-if="compareOptions.length"
        :options="compareOptions"
        :optionsText="props.optionsText"
        :searchCompare="props.searchCompare"
        :instrument="instrument"
        :addCompare="addCompare"
        :removeCompare="removeCompare"
        :selectedCompares="selectedCompares"
      ></Dropdown>
      <div
        class="mobile-button-container"
        :class="showInterests ? ' open' : ''"
        v-if="props.interestOptions.length"
        v-click-outside="() => (showInterests = false)"
      >
        <div id="selected-div" @click="showInterests = !showInterests">
          <span>{{ selectedInterest.text }}</span>
          <div class="arrow">
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </div>
        <div class="mobile-button-options" v-show="showInterests">
          <div
            class="mobile-button-option"
            v-for="elem in filteredInterestOptions"
            :key="elem"
            @click="changeInterest(elem)"
          >
            {{ elem.text }}
          </div>
        </div>
      </div>
    </div>
    <div id="millistream-selector"></div>
  </div>
</template>

<script>
import { onMounted, ref, toRef, watch, computed, reactive } from "vue";
import List from "@/components/List.vue";
import Search from "@/components/Search.vue";
import Dropdown from "@/components/CompareDropDown.vue";
import vClickOutside from "click-outside-vue3";
import $ from "jquery";
import { token } from "@/utilities/token";
import { disableDowJonesIndices } from "@/utilities/indexList";
import { dowJones } from "../utilities/indexList";
export default {
  components: {
    List,
    Dropdown,
    Search,
  },
  props: {
    instrument: {
      type: Number,
      required: true,
    },
    searchCompare: {
      type: Boolean,
      default: false,
    },
    optionsText: {
      type: String,
      default: "Jämför med Index",
    },
    targetId: {
      type: String,
      required: true,
    },
    timespanelement: {
      type: String,
      default: () => {
        return null;
      },
    },
    chartlen: {
      type: String,
      default: () => {
        return "1d";
      },
    },
    dateformat: {
      type: String,
      default: () => {
        return "d/m";
      },
    },
    timeperiod: {
      type: String,
      default: () => {
        return "time-period";
      },
    },
    intervalButtons: {
      type: Array,
      default: () => {
        return [];
      },
    },
    compareOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    header: {
      type: Boolean,
      default: false,
    },
    headerFields: {
      type: Array,
      default: () => {
        return ["name", "symbol", "diff1dprc"];
      },
    },
    headerCustomNames: {
      type: Object,
      default: () => {
        return {};
      },
    },
    headerControlcolumn: {
      type: Number,
      default: null,
    },
    headerStylecolumn: {
      type: Array,
      default: () => {
        return [];
      },
    },
    historylen: {
      type: String,
      default: null,
    },
    intradaylen: {
      type: String,
      default: "5",
    },
    hcurve: {
      type: Boolean,
      default: false,
    },
    interestOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    customH1Name: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup(props, { emit }) {
    let chart;
    MillistreamWidgetSettings.language = "sv";
    MillistreamWidgetSettings.token = token;
    const instrument = computed({
      get: () => props.instrument,
      set: (value) => emit("update:instrument", value),
    });

    const compareOptions = reactive([]);
    const selectedCompares = reactive([]);
    const interestOptions = toRef(props, "interestOptions");
    const showIntervals = ref(false);
    const showInterests = ref(false);
    const tabindex = 0;
    const selectedInterval = computed(() => {
      return props.intervalButtons.find(
        (elem) => elem.active === true && elem.disabled !== true
      );
    });
    const filteredInterestOptions = computed(() => {
      return interestOptions.value.filter(
        (elem) => elem.insref && elem.active === false
      );
    });
    const selectedInterest = computed(() => {
      return interestOptions.value.find((elem) => elem.active === true);
    });
    const changeInterest = (elem) => {
      interestOptions.value.forEach((element) => {
        element.active = false;
      });
      elem.active = true;
      instrument.value = elem.insref;
      showInterests.value = false;
    };
    const headerName = ref(null);
    const buildChart = () => {
      chart = new Milli_Chart(
        {
          target: document.getElementById(props.targetId),
          instrument: instrument.value,
          // graphvalue: props.graphvalue,
          enablezoom: false,
          intradaylen: props.intradaylen,
          historylen: props.historylen,
          gridHorizontalLines: true,
          gridHorizontalLinesStyle: "line",
          gridVerticalLines: true,
          chartlen: props.chartlen,
          dateformat: props.dateformat,
          drawyaxis: true,
          drawy2axis: false,
          drawxaxis: true,
          hcurve: props.hcurve,
          startdate: "2019-11-18",
          fillchart: true,
          yearLabelsPos: "top",
          timeformat: "HH:mm",
          timespanelement: document.getElementById(props.timespanelement),
          verticalLegendStyle: {
            float: "right",
            verticalAlign: "top",
            fontSize: "10px",
            color: "rgba(37, 45, 64, 0.5)",
          },
          horizontalLegendStyle: {
            fontWeight: "bold",
            fontSize: "12px",
            color: "rgba(37, 45, 64, 0.5)",
          },
          onData: (e) => {
            if (e.insref === instrument.value) headerName.value = e.name;
          },
          tooltip: {
            formatter: function () {
              let date = new Date(this.data.timestamp);
              const hours = date.getHours();
              const minutes = date.getMinutes();
              const formattedHours = hours < 10 ? "0" + hours : hours;
              const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
              const time = formattedHours + ":" + formattedMinutes;
              date = formatDate(date, "yyyy-mm-dd");
              let span =
                '<span style="font-weight:bold">' +
                this.name +
                "</span><br>Pris:" +
                this.data.price +
                "</br>Datum:" +
                date +
                "</br>";
              if (
                chart.settings.chartlen == "1d" ||
                chart.settings.chartlen == "0d" ||
                chart.settings.chartlen == "5d"
              )
                span += "Tid:" + time;
              return span;
            },
          },
          instrumentStyle: {
            color: "#E3507A",
            width: 2,
            backgroundLinearGradient: {
              topColor: "rgba(226, 80, 122, 0.6",
              bottomColor: "rgba(226, 80, 122, 0)",
            },
          },
          compare1Style: {
            color: "#e39550",
            width: 2,
          },
          compare2Style: {
            color: "#446CAE",
            width: 2,
          },
          compare3Style: {
            color: "#27B16F",
            width: 2,
          },
          chartStyle: {
            marginRight: 16,
            marginLeft: 16,
            marginTop: 0,
            marginBottom: 20,
            boxShadow: {
              topWidth: 0,
              rightWidth: 0,
            },
          },
        },
        document
          .getElementById(props.timeperiod)
          .addEventListener("change", function (e) {
            chart.setChartLength(e.target.value);
            //traderchart.settings.chartlen = e.target.value;
            chart.drawChart();
          })
      );
    };
    const buildWidget = () => {
      new Milli_Selector({
        target: document.getElementById("millistream-selector"),
        // marketplace: [3941164],
        instrumenttype: null,
        instrument: props.compareOptions,
        link_field: ["insref"],
        fields: ["name", "insref"],
        order: ["asc"],
        orderby: ["name"],
        onreadyCallback: (e) => {
          $(e.children).each((index, option) => {
            compareOptions.push({
              insref: parseInt(option.value),
              active: false,
              name: option.textContent,
            });
          });
        },
      });
    };
    const changeChartInterval = (selected) => {
      props.intervalButtons.forEach((element) => {
        element.active = false;
      });
      selected.active = true;
      let timeperiod = document.getElementById(props.timeperiod);
      timeperiod.value = selected.value;
      timeperiod.dispatchEvent(new Event("change"));
      showIntervals.value = false;
    };
    const removeCompare = (elem) => {
      elem.active = false;
      elem.class = null;
      const index = selectedCompares.indexOf(elem);
      selectedCompares[index] = undefined;
      chart.removeCompare(elem.insref);
    };
    const addCompare = (elem) => {
      if (selectedCompares.find((currentElement) => currentElement == elem))
        return;
      if (selectedCompares.indexOf(undefined) == -1)
        selectedCompares.push(elem);
      else {
        const index = selectedCompares.indexOf(undefined);
        selectedCompares[index] = elem;
      }

      disableDowJonesIndices({
        selectedIndex: elem.insref,
        intervalButtons: props.intervalButtons,
        changeChartInterval,
      });

      chart.addCompare(elem.insref);
    };
    // watch(selectedCompares, () => {
    //   selectedCompares.some((item) => {
    //     if (!item) return;
    //     disableDowJonesIndices({
    //       selectedIndex: item.insref,
    //       intervalButtons: props.intervalButtons,
    //       changeChartInterval,
    //     });
    //   });
    // });
    onMounted(() => {
      buildChart();
      if (props.compareOptions.length) buildWidget();
    });
    watch(instrument, (newVal, oldVal) => {
      disableDowJonesIndices({
        selectedIndex: instrument.value,
        intervalButtons: props.intervalButtons,
        changeChartInterval,
      });

      if (interestOptions.value.length) {
        interestOptions.value.forEach((element) => {
          element.active = false;
        });
        const selected = interestOptions.value.find(
          (elem) => elem.insref === instrument.value
        );
        selected.active = true;
      }
      //   const exists = selectedCompares.find(item => item.insref === instrument.value.insref)
      //   if(exists) {
      const exists = selectedCompares.find((item) => item?.insref === newVal);
      if (exists) removeCompare(exists);
      //   }

      //   selectedCompares.length = 0;
      //   compareOptions.map((elem) => {
      //     elem.active = false;
      //     elem.class = null;
      //   });
      chart.settings.instrument = newVal;
      chart.drawWidget();
    });

    return {
      changeChartInterval,
      props,
      showIntervals,
      selectedInterval,
      instrument,
      tabindex,
      showInterests,
      removeCompare,
      compareOptions,
      addCompare,
      selectedInterest,
      filteredInterestOptions,
      changeInterest,
      selectedCompares,
      headerName,
    };
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";

.chart-header {
  .box-container {
    padding: 0;
    width: 100%;
  }

  .compare-1 {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: Sueca Hd;
    vertical-align: bottom;
    font-style: normal;
    font-weight: 600;
    color: #252d40;
    gap: 0.5rem;

    &::before {
      content: "";
      border-radius: 50%;
      height: 0.5rem;
      width: 0.5rem;
      background-color: $orange;
    }

    &:hover {
      text-decoration: line-through;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .compare-2 {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: Sueca Hd;
    vertical-align: bottom;
    font-style: normal;
    font-weight: 600;
    color: #252d40;
    gap: 0.5rem;

    &::before {
      content: "";
      border-radius: 50%;
      height: 0.5rem;
      width: 0.5rem;
      background-color: $blue;
    }

    &:hover {
      text-decoration: line-through;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .compare-3 {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: Sueca Hd;
    vertical-align: bottom;
    font-style: normal;
    font-weight: 600;
    color: #252d40;
    gap: 0.5rem;

    &::before {
      content: "";
      border-radius: 50%;
      height: 0.5rem;
      width: 0.5rem;
      background-color: $green;
    }

    &:hover {
      text-decoration: line-through;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  table {
    width: 100%;

    thead {
      display: none;
    }

    tbody {
      tr {
        // font-family: Sueca Hd;
        font-style: normal;
        font-weight: 600;

        // font-size: 13px;
        // line-height: 16px;
        td {
          &.millistream-name {
            text-align: left;
            font-family: Sueca Hd;
            vertical-align: bottom;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 22px;
            /* Footer */
            color: #252d40;
          }

          &.millistream-symbol {
            background: rgba(37, 45, 64, 0.08);
            padding: 4px 6px;
            font-family: SuecaSans;
            display: block;
            margin: 3px auto;
            font-style: normal;
            border-radius: 2px;
            font-weight: bold;
            font-size: 10px;
            width: 70px;
            line-height: 12px;
            color: rgba(37, 45, 64, 0.5);
          }

          &.millistream-diff1dprc {
            background: rgba(37, 45, 64, 0.04);
            border-radius: 28px;
            padding: 6px 0;
            font-size: 12px;
            line-height: 14px;
            width: 70px;
            font-weight: 600;

            &.positive {
              color: $green;

              &::before {
                margin-right: 3px;
                content: url("~@/assets/images/arrow_green.png");
                vertical-align: text-top;
              }
            }

            &.negative {
              color: $red;

              &::before {
                margin-right: 3px;
                vertical-align: text-top;

                content: url("~@/assets/images/arrow_red.png");
              }
            }

            &::after {
              content: "%";
            }
          }
        }
      }
    }
  }
}

.below-chart-menu {
  display: flex;
  align-items: center;
}

.compare-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  margin-top: 0.5rem;

  column-gap: 1rem;
}

.chart-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.mobile-button-option {
  &.selected-option {
    background: rgba(37, 45, 64, 0.08);
  }
}

.selectHide {
  display: none;
}

#chart-container {
  position: relative;
}

@media (min-width: 781px) {
  .chart-header {
    .box-container {
      padding-left: 1rem;
    }
  }

  .compare-wrapper {
    padding-left: 1rem;
  }

  .below-chart-menu {
    .mobile-button-container#intervall-drop-down {
      display: none;
    }
  }
}

@media (max-width: 781px) {
  .below-chart-menu {
    flex-direction: column-reverse;
    gap: 1rem;

    .chart-button-container {
      display: none;
    }

    .custom-select {
      width: 100%;
    }

    .chart-button-container {
      gap: 1rem;
      padding: 0;
    }
  }
}
</style>
